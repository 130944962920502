<template>
  <v-row>
    <v-col v-if="!actionOption" cols="12">
      <v-row>
        <v-col class="bold-text font-size-h3" cols="12">
          Manage team addons
        </v-col>
        <v-col cols="12" md="6">
          <v-card
            flat
            color="success"
            class="bold-text pa-4 text-center font-size-h3 white--text"
            @click="actionOption = 'add'"
          >
            Add extra {{ addonType }}
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card
            flat
            :disabled="disableRemove"
            :color="disableRemove ? 'grey' : 'error'"
            @click="actionOption = 'remove'"
            class="bold-text text-center font-size-h3 pa-4 white--text"
          >
            Remove extra {{ addonType }}
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else cols="12">
      <v-row>
        <v-col class="bold-text font-size-h3 mt-4" cols="12">
          Manage {{ addonType }} addons
        </v-col>
        <v-col v-if="addonType === 'apps'" class="bodyFont" cols="12">
          I want to {{ actionOption === "add" ? "have" : "remove" }}
          <v-icon
            v-text="'mdi-minus'"
            left
            right
            @click="decreaseApps"
          ></v-icon>
          <b>{{ apps }}</b>
          <v-icon left right v-text="'mdi-plus'" @click="increaseApps"></v-icon>
          extra app<span v-if="apps > 1">s</span> in this team
        </v-col>
        <v-col v-if="addonType === 'members'" class="bodyFont" cols="12">
          I want to {{ actionOption === "add" ? "have" : "remove" }}
          <v-icon
            v-text="'mdi-minus'"
            left
            right
            @click="decreaseMembers"
          ></v-icon>
          <b>
            {{ members }}
          </b>
          <v-icon
            v-text="'mdi-plus'"
            left
            right
            @click="increaseMembers"
          ></v-icon>
          extra member<span v-if="members > 1">s</span> from this team
        </v-col>
        <v-col class="text-right" cols="12">
          <v-btn
            :disabled="loading"
            color="primary"
            text
            @click="$emit('close')"
            class="text-transform-none mx-3"
            >Cancel</v-btn
          >
          <v-btn
            :loading="loading"
            class="text-transform-none"
            color="primary"
            :disabled="!apps && !members"
            @click="submit()"
            >Proceed</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { ADD_ADDONS, REMOVE_ADDONS } from "@/store/team/team.module";
import { GET_USER_ROLES } from "@/store/users/auth.module";
export default {
  props: {
    addonType: {
      type: String,
      default: "",
    },
    fromUpgrade: {
      type: Boolean,
      default: false,
    },
    addonStats: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      apps: 1,
      members: 1,
      actionOption: "",
      loading: false,
    };
  },
  computed: {
    disableRemove() {
      if (this.addonType == "members") {
        return !this.addonStats.members;
      } else if (this.addonType == "apps") {
        return !this.addonStats.apps;
      }
      return false;
    },
  },
  created() {
    if (this.fromUpgrade) {
      this.actionOption = "add";
    }
  },
  methods: {
    submit() {
      if (this.actionOption === "add") {
        this.addAddons();
      } else {
        this.removeAddons();
      }
    },
    addAddons() {
      if (this.members || this.apps) {
        if (this.loading) return;
        this.loading = true;
        const dataToPost = {
          members: this.addonType == "members" ? this.members : 0,
          apps: this.addonType == "apps" ? this.apps : 0,
        };
        this.$store
          .dispatch(ADD_ADDONS, dataToPost)
          .then((response) => {
            this.notifyUserMessage({ message: response.message });
            this.loading = false;
            this.$store.dispatch(GET_USER_ROLES).catch((err) => {
              this.notifyErrorMessage(err.message);
              this.busy = false;
            });
            this.$emit("close");
            this.$emit("success");
          })
          .catch((err) => {
            this.notifyErrorMessage(err.message);
            this.loading = false;
          });
      } else {
        this.notifyErrorMessage({
          message: "Please select at least one app or member for addons",
        });
      }
    },
    removeAddons() {
      if (this.members || this.apps) {
        if (this.loading) return;
        this.loading = true;
        const dataToPost = {
          type: this.addonType,
          amount: this.addonType === "apps" ? this.apps : this.members,
        };
        this.$store
          .dispatch(REMOVE_ADDONS, dataToPost)
          .then((response) => {
            this.notifyUserMessage({ message: response.message });
            this.$store.dispatch(GET_USER_ROLES).catch((err) => {
              this.notifyErrorMessage(err.message);
              this.loading = false;
            });
            this.$emit("close");
            this.$emit("success");
          })
          .catch((err) => {
            this.notifyErrorMessage(err.message);
            this.loading = false;
          });
      } else {
        this.notifyErrorMessage({
          message: "Please select at least one app or member for addons",
        });
      }
    },

    decreaseMembers() {
      if (this.members) {
        this.members =
          this.members > 1 ? parseInt(this.members) - 1 : this.members;
      }
    },
    decreaseApps() {
      if (this.apps) {
        this.apps = this.apps > 1 ? parseInt(this.apps) - 1 : this.apps;
      }
    },
    getMembersText() {
      if (this.addonStats.members && this.members > this.addonStats.members) {
        return `You are adding ${this.members -
          this.addonStats.members} member in your team`;
      } else if (
        this.addonStats.members &&
        parseInt(this.members) < parseInt(this.addonStats.members)
      ) {
        return `You are descreasing ${parseInt(this.addonStats.members) -
          parseInt(this.members)} member in your team`;
      } else {
        return "Increase or descrea a count to update";
      }
    },
    getAppsText() {
      if (this.addonStats.apps && this.apps > this.addonStats.apps) {
        return `You are adding ${this.apps -
          this.addonStats.apps} app in your team`;
      } else if (
        this.addonStats.apps &&
        parseInt(this.apps) < parseInt(this.addonStats.apps)
      ) {
        return `You are descreasing ${parseInt(this.addonStats.apps) -
          parseInt(this.apps)} app in your team`;
      } else {
        return "Increase or descrea a count to update";
      }
    },
    increaseApps() {
      if (
        this.addonStats &&
        this.addonStats.apps === this.apps &&
        this.actionOption === "remove"
      ) {
        this.notifyErrorMessage({
          message: "You cannot remove more then total addons",
        });
        return;
      }
      this.apps = parseInt(this.apps) + 1;
    },
    increaseMembers() {
      if (
        this.addonStats &&
        this.addonStats.members === this.members &&
        this.actionOption === "remove"
      ) {
        this.notifyErrorMessage({
          message: "You cannot remove more then limit total addons",
        });
        return;
      }
      this.members = parseInt(this.members) + 1;
    },
  },
};
</script>
