var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [!_vm.actionOption ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "bold-text font-size-h3",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Manage team addons ")]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "bold-text pa-4 text-center font-size-h3 white--text",
    attrs: {
      "flat": "",
      "color": "success"
    },
    on: {
      "click": function click($event) {
        _vm.actionOption = 'add';
      }
    }
  }, [_vm._v(" Add extra " + _vm._s(_vm.addonType) + " ")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "bold-text text-center font-size-h3 pa-4 white--text",
    attrs: {
      "flat": "",
      "disabled": _vm.disableRemove,
      "color": _vm.disableRemove ? 'grey' : 'error'
    },
    on: {
      "click": function click($event) {
        _vm.actionOption = 'remove';
      }
    }
  }, [_vm._v(" Remove extra " + _vm._s(_vm.addonType) + " ")])], 1)], 1)], 1) : _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "bold-text font-size-h3 mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Manage " + _vm._s(_vm.addonType) + " addons ")]), _vm.addonType === 'apps' ? _c('v-col', {
    staticClass: "bodyFont",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" I want to " + _vm._s(_vm.actionOption === "add" ? "have" : "remove") + " "), _c('v-icon', {
    attrs: {
      "left": "",
      "right": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-minus')
    },
    on: {
      "click": _vm.decreaseApps
    }
  }), _c('b', [_vm._v(_vm._s(_vm.apps))]), _c('v-icon', {
    attrs: {
      "left": "",
      "right": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-plus')
    },
    on: {
      "click": _vm.increaseApps
    }
  }), _vm._v(" extra app"), _vm.apps > 1 ? _c('span', [_vm._v("s")]) : _vm._e(), _vm._v(" in this team ")], 1) : _vm._e(), _vm.addonType === 'members' ? _c('v-col', {
    staticClass: "bodyFont",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" I want to " + _vm._s(_vm.actionOption === "add" ? "have" : "remove") + " "), _c('v-icon', {
    attrs: {
      "left": "",
      "right": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-minus')
    },
    on: {
      "click": _vm.decreaseMembers
    }
  }), _c('b', [_vm._v(" " + _vm._s(_vm.members) + " ")]), _c('v-icon', {
    attrs: {
      "left": "",
      "right": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-plus')
    },
    on: {
      "click": _vm.increaseMembers
    }
  }), _vm._v(" extra member"), _vm.members > 1 ? _c('span', [_vm._v("s")]) : _vm._e(), _vm._v(" from this team ")], 1) : _vm._e(), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none mx-3",
    attrs: {
      "disabled": _vm.loading,
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "loading": _vm.loading,
      "color": "primary",
      "disabled": !_vm.apps && !_vm.members
    },
    on: {
      "click": function click($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v("Proceed")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }