<template>
  <div>
    <div v-if="getInactiveUserMessage">
      <no-data :first-text="getInactiveUserMessage"></no-data>
    </div>
    <div v-else>
      <v-row no-gutters>
        <v-col class="d-flex mb-8" cols="12">
          <v-spacer />
          <v-btn
            v-if="canManageStorage"
            :outlined="$vuetify.theme.dark"
            class="text-transform-none"
            color="primary"
            @click="openStorageForm"
          >
            <v-icon left v-text="'mdi-plus'" />
            Add New
          </v-btn>
        </v-col>
        <v-col v-if="!isInfoLoaded && canViewStorage" cols="12">
          <v-skeleton-loader
            v-if="dataLoader"
            class="transparent"
            type="table-thead, table-tbody"
          />
          <DatatableWrapper
            v-else
            :loading="loading"
            :pagination="pagination"
            @onChange="handleOnChange"
          >
            <v-data-table
              :expanded.sync="expanded"
              :headers="headers"
              :items="storageList"
              mobile-breakpoint="0"
              hide-default-footer
              show-expand
              single-expand
              @click:row="expendRow"
            >
              <template #item.icon="{ item }">
                <v-img
                  :alt="item.name"
                  :src="getStorageImg(item.icon)"
                  contain
                  height="32"
                  width="32"
                />
              </template>

              <template #item.usage="{ item }">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <span v-on="on">
                      {{ formatBytes(getSumForStorage(item.meta.storage)) }}
                    </span>
                  </template>
                  <span>
                    <div>
                      <ul>
                        <li>
                          Attachments:
                          <ul>
                            <li>
                              Feedback:
                              {{
                                formatBytes(
                                  get(
                                    item,
                                    "meta.storage.attachments.feedback.active",
                                    0
                                  )
                                )
                              }}
                            </li>
                            <li>
                              Comments:
                              {{
                                formatBytes(
                                  get(
                                    item,
                                    "meta.storage.attachments.comments.active",
                                    0
                                  )
                                )
                              }}
                            </li>
                          </ul>
                        </li>
                        <li>
                          Releases:
                          {{
                            formatBytes(
                              get(item, "meta.storage.releases.active", 0)
                            )
                          }}
                        </li>
                      </ul>
                    </div>
                  </span>
                </v-tooltip>
              </template>

              <template #item.limit="{ item }">
                {{
                  item.meta.limit == 0 || !item.meta.limit
                    ? "Unlimited"
                    : formatBytes(item.meta.limit)
                }}
              </template>

              <template #item.apps="{ item }">
                {{ item.meta.apps }}
              </template>

              <template #item.releases="{ item }">
                {{ item.meta.releases }}
              </template>

              <template #item.attachment="{ item }">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <span v-on="on">
                      {{
                        item?.meta?.attachments?.feedback +
                        (item?.meta?.attachments?.comments || 0)
                      }}
                    </span>
                  </template>
                  <ul>
                    <li>Feedback: {{ item?.meta?.attachments?.feedback }}</li>
                    <li>
                      Comments: {{ item?.meta?.attachments?.comments || 0 }}
                    </li>
                  </ul>
                </v-tooltip>
              </template>

              <template #item.data-table-expand="{ item, expand, isExpanded }">
                <v-icon v-if="item.value">mdi-minus</v-icon>
                <v-btn v-else icon @click.stop="expand(!isExpanded)">
                  <v-icon
                    v-text="isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                  />
                </v-btn>
              </template>

              <template #expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-0">
                  <v-card flat tile>
                    <v-card-text>
                      <v-row>
                        <v-col cols="4">
                          <v-icon
                            size="20"
                            v-text="'mdi-chevron-right'"
                          ></v-icon>
                          Origin:
                          <b>{{ item.name }}</b>
                        </v-col>
                        <v-col cols="4">
                          <v-icon
                            size="20"
                            v-text="'mdi-chevron-right'"
                          ></v-icon>
                          Region: <b>{{ item.region || "-" }}</b>
                        </v-col>
                        <v-col cols="4">
                          <v-icon
                            size="20"
                            v-text="'mdi-chevron-right'"
                          ></v-icon>
                          Endpoint: <b>{{ item.endpoint || "-" }}</b>
                        </v-col>
                        <v-col cols="4">
                          <v-icon
                            size="20"
                            v-text="'mdi-chevron-right'"
                          ></v-icon>
                          Key: <b>{{ item.key || "-" }}</b>
                        </v-col>
                        <v-col cols="4">
                          <v-icon
                            size="20"
                            v-text="'mdi-chevron-right'"
                          ></v-icon>
                          Secret: <b>{{ item.secret || "-" }}</b>
                        </v-col>
                        <v-col cols="4">
                          <v-btn
                            class="white--text font-weight-bold text-capitalize"
                            color="red"
                            depressed
                            small
                            @click="
                              [
                                (currentStorage = item),
                                (showRemoveModal = true),
                              ]
                            "
                          >
                            Remove
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </td>
              </template>

              <template slot="no-data">
                <no-data
                  v-if="getTeamPlanUsage && !getTeamPlanUsage.connect_storage"
                  first-text="Connecting external storage available for Starter & Pro plans."
                ></no-data>
              </template>
            </v-data-table>
          </DatatableWrapper>
        </v-col>
        <v-col v-else class="no-data-found">
          <no-data
            first-text="No storage to show"
            second-text="It seems that you don't have the permission to manage the team storage"
          ></no-data>
        </v-col>
        <SidePanel v-model="showStorageForm">
          <create-form
            v-if="showStorageForm"
            @refresh="showStorageForm = false"
          />
        </SidePanel>

        <Modal v-model="showRemoveModal">
          <template #card>
            <v-card>
              <v-card-title class="font-weight-bold"
                >Remove storage
              </v-card-title>

              <v-divider />
              <v-card-text class="my-4 message">
                <p>Removing a storage you agree for removing the following:</p>
                <div>
                  <ul>
                    <li>
                      Apps (<span class="text-red">{{
                        currentStorage?.meta?.apps || 0
                      }}</span
                      >)
                    </li>
                    <ul>
                      <li>
                        Releases (<span class="text-red">{{
                          currentStorage?.meta?.releases || 0
                        }}</span
                        >)
                      </li>
                      <li>
                        Feedback (<span class="text-red">{{
                          currentStorage?.meta?.attachments?.feedback || 0
                        }}</span
                        >)
                      </li>
                      <li>Timeline</li>
                      <li>Members</li>
                      <li>Chat</li>
                    </ul>

                    <li>Storage</li>
                    <ul>
                      <li>Name: {{ currentStorage.name }}</li>
                      <li>Bucket: {{ currentStorage.bucket }}</li>
                      <li>Region: {{ currentStorage.region }}</li>
                    </ul>
                  </ul>
                </div>
                <br />
                <p>Are you sure want to proceed?</p>
              </v-card-text>
              <v-divider />

              <!-- and for the actions, submit... -->
              <v-card-actions class="py-3">
                <v-spacer />
                <v-btn
                  class="text-transform-none"
                  color="primary"
                  text
                  @click="showRemoveModal = false"
                  >No
                </v-btn>
                <v-btn
                  :loading="busy"
                  :outlined="$vuetify.theme.dark"
                  class="text-transform-none"
                  color="primary"
                  depressed
                  @click="deleteStorage"
                >
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </Modal>
        <Modal
          v-model="showStartTrialModal"
          :fullscreen="$vuetify.breakpoint.mdAndDown"
          :width="$vuetify.breakpoint.mdAndUp ? 880 : ''"
          no-py
          @close="showStartTrialModal = false"
        >
          <template #message>
            <start-trial-modal
              v-if="showStartTrialModal"
              :tracker-event="trackerEvent"
              from-connect-storage
              message="Connecting external storage is available for Starter and Pro plans"
              trigger-from="externalStorage"
              @close="showStartTrialModal = false"
              @open-subscribe="openSubscriptionModal = true"
            ></start-trial-modal>
          </template>
        </Modal>
        <Modal v-model="showUpgradeInfo" @close="showUpgradeInfo = false">
          <template #message>
            <upgrade-plan
              from-external-storage
              info-text="add external storage to your team"
              title="Connect External Storage"
              @close="showUpgradeInfo = false"
            >
              <template>
                <div>
                  Do you need more storage size? Connect your external cloud
                  storage.<br /><br />

                  <b>The benefits:</b> <br /><br />

                  - Unlimited releases<br />
                  - No release expiry date<br />
                  - No bandwidth restrictions<br />

                  <br />This is free of charge from our end but charges will
                  apply from the cloud storage provider.
                </div>
              </template>
            </upgrade-plan>
          </template>
        </Modal>
      </v-row>
    </div>
  </div>
</template>

<script>
import { DELETE_STORAGE, GET_STORAGE } from "@/store/team/storage.module";
import CreateForm from "@/view/components/Team/Storage/CreateForm";
import { formatBytes, get, isEmpty } from "@/core/services/helper.service";
import UpgradePlan from "@/view/components/Team/UpgradePlan.vue";
import StartTrialModal from "@/view/components/Common/StartTrialModal";
import { mapGetters, mapMutations } from "vuex";
import DatatableWrapper from "@/view/components/App/DatatableWrapper";
// import StorageProgress from "@/view/components/Common/StorageProgress";

export default {
  components: { DatatableWrapper, CreateForm, StartTrialModal, UpgradePlan },
  props: {
    bucket: {
      type: String,
      default: "",
    },
    usage: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      get,
      isEmpty,
      formatBytes,
      expanded: [],
      dataLoader: false,
      showStartTrialModal: false,
      showUpgradeInfo: false,
      headers: [
        {
          text: "",
          sortable: false,
          align: "start",
          width: "50px",
          value: "icon",
        },
        {
          text: "Name",
          sortable: false,
          align: "start",
          value: "name",
        },
        {
          text: "Bucket",
          sortable: false,
          align: "start",
          value: "bucket",
        },
        {
          text: "Usage",
          sortable: false,
          align: "start",
          value: "usage",
        },
        {
          text: "Limit",
          sortable: false,
          align: "start",
          value: "limit",
        },
        {
          text: "Apps",
          sortable: false,
          align: "start",
          value: "apps",
        },
        {
          text: "Releases",
          sortable: false,
          align: "start",
          value: "releases",
        },
        {
          text: "Attachments",
          sortable: false,
          align: "start",
          value: "attachment",
        },
        {
          text: "Action",
          sortable: false,
          align: "start",
          value: "data-table-expand",
        },
      ],
      showRemoveModal: false,
      currentStorage: {},
      trackerEvent: {},
      defaultStorage: {},
      busy: false,
      showStorageForm: false,
      loading: false,
      pagination: {
        page: 1,
        offset: 0,
        limit: 10,
        itemsPerPage: 10,
        totalItems: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      externalStorageList: "getExternalStorageList",
      getTeamPlanUsage: "getTeamPlanUsage",
      getTeamUsageInformation: "getTeamUsageInformation",
      getInactiveUserMessage: "getInactiveUserMessage",
    }),
    storageList() {
      if (!isEmpty(this.defaultStorage)) {
        return [this.defaultStorage, ...this.externalStorageList];
      }
      return [];
    },
  },
  watch: {
    getTeamPlanUsage() {
      this.getStorageList();
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.getInactiveUserMessage) {
        if (this.canViewStorage) {
          this.getStorageList();
        }
      }
    });
  },
  methods: {
    ...mapMutations({
      removeStorageStats: "removeStorageStats",
    }),
    getSumForStorage(data) {
      if (isEmpty(data)) {
        return 0;
      } else {
        return (
          parseInt(data?.releases?.active || 0) +
          parseInt(data?.attachments?.feedback?.active || 0) +
          parseInt(data?.attachments?.comments?.active || 0)
        );
      }
    },
    deleteStorage() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(DELETE_STORAGE, this.currentStorage)
        .then((response) => {
          this.removeStorageStats({
            app: this.currentStorage.meta.total_apps,
            releases: this.currentStorage.meta.total_releases || 0,
            storage: this.currentStorage.meta.storage || 0,
          });
          this.notifyUserMessage({
            message: response.message || "Storage successfully removed",
          });
          this.busy = false;
          this.showRemoveModal = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message || "something went wrong!");
          this.busy = false;
          this.showRemoveModal = false;
        });
    },
    getStorageList() {
      if (this.dataLoader) return;
      this.dataLoader = true;
      this.$store
        .dispatch(GET_STORAGE)
        .then((response) => {
          this.defaultStorage = {
            name: "TestApp.io",
            icon: "testapp.io.svg",
            value: "default",
            bucket: "-",
            meta: {
              storage: response.internal.storage || 0,
              limit: this.getTeamUsageInformation.storage,
              apps: response.internal.apps,
              attachments: {
                feedback: response.internal.attachments.feedback,
                comments: response.internal.attachments.comments,
              },
              releases: response.internal.releases,
            },
          };
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
        })
        .finally(() => {
          this.dataLoader = false;
        });
    },
    openStorageForm() {
      if (
        this.getTeamUsageInformation &&
        !this.getTeamUsageInformation.connect_storage
      ) {
        this.trackerEvent = {
          event: "Connect Storage",
          current: "",
          requested: "",
        };
        this.showStartTrialModal = true;
      } else {
        this.showStorageForm = true;
      }
    },
    expendRow(item) {
      if (!item.value) {
        this.expanded = item === this.expanded[0] ? [] : [item];
      }
    },
    handleOnChange(value) {
      this.pagination.page = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-red {
  color: #e75146;
}
</style>
