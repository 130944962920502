var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.getInactiveUserMessage ? _c('div', [_c('no-data', {
    attrs: {
      "first-text": _vm.getInactiveUserMessage
    }
  })], 1) : _c('div', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "d-flex mb-8",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-spacer'), _vm.canManageStorage ? _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary"
    },
    on: {
      "click": _vm.openStorageForm
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-plus')
    }
  }), _vm._v(" Add New ")], 1) : _vm._e()], 1), !_vm.isInfoLoaded && _vm.canViewStorage ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.dataLoader ? _c('v-skeleton-loader', {
    staticClass: "transparent",
    attrs: {
      "type": "table-thead, table-tbody"
    }
  }) : _c('DatatableWrapper', {
    attrs: {
      "loading": _vm.loading,
      "pagination": _vm.pagination
    },
    on: {
      "onChange": _vm.handleOnChange
    }
  }, [_c('v-data-table', {
    attrs: {
      "expanded": _vm.expanded,
      "headers": _vm.headers,
      "items": _vm.storageList,
      "mobile-breakpoint": "0",
      "hide-default-footer": "",
      "show-expand": "",
      "single-expand": ""
    },
    on: {
      "update:expanded": function updateExpanded($event) {
        _vm.expanded = $event;
      },
      "click:row": _vm.expendRow
    },
    scopedSlots: _vm._u([{
      key: "item.icon",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-img', {
          attrs: {
            "alt": item.name,
            "src": _vm.getStorageImg(item.icon),
            "contain": "",
            "height": "32",
            "width": "32"
          }
        })];
      }
    }, {
      key: "item.usage",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on;
              return [_c('span', _vm._g({}, on), [_vm._v(" " + _vm._s(_vm.formatBytes(_vm.getSumForStorage(item.meta.storage))) + " ")])];
            }
          }], null, true)
        }, [_c('span', [_c('div', [_c('ul', [_c('li', [_vm._v(" Attachments: "), _c('ul', [_c('li', [_vm._v(" Feedback: " + _vm._s(_vm.formatBytes(_vm.get(item, "meta.storage.attachments.feedback.active", 0))) + " ")]), _c('li', [_vm._v(" Comments: " + _vm._s(_vm.formatBytes(_vm.get(item, "meta.storage.attachments.comments.active", 0))) + " ")])])]), _c('li', [_vm._v(" Releases: " + _vm._s(_vm.formatBytes(_vm.get(item, "meta.storage.releases.active", 0))) + " ")])])])])])];
      }
    }, {
      key: "item.limit",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.meta.limit == 0 || !item.meta.limit ? "Unlimited" : _vm.formatBytes(item.meta.limit)) + " ")];
      }
    }, {
      key: "item.apps",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.meta.apps) + " ")];
      }
    }, {
      key: "item.releases",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.meta.releases) + " ")];
      }
    }, {
      key: "item.attachment",
      fn: function fn(_ref7) {
        var _item$meta3, _item$meta3$attachmen, _item$meta4, _item$meta4$attachmen;
        var item = _ref7.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref8) {
              var _item$meta, _item$meta$attachment, _item$meta2, _item$meta2$attachmen;
              var on = _ref8.on;
              return [_c('span', _vm._g({}, on), [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : (_item$meta = item.meta) === null || _item$meta === void 0 ? void 0 : (_item$meta$attachment = _item$meta.attachments) === null || _item$meta$attachment === void 0 ? void 0 : _item$meta$attachment.feedback) + ((item === null || item === void 0 ? void 0 : (_item$meta2 = item.meta) === null || _item$meta2 === void 0 ? void 0 : (_item$meta2$attachmen = _item$meta2.attachments) === null || _item$meta2$attachmen === void 0 ? void 0 : _item$meta2$attachmen.comments) || 0)) + " ")])];
            }
          }], null, true)
        }, [_c('ul', [_c('li', [_vm._v("Feedback: " + _vm._s(item === null || item === void 0 ? void 0 : (_item$meta3 = item.meta) === null || _item$meta3 === void 0 ? void 0 : (_item$meta3$attachmen = _item$meta3.attachments) === null || _item$meta3$attachmen === void 0 ? void 0 : _item$meta3$attachmen.feedback))]), _c('li', [_vm._v(" Comments: " + _vm._s((item === null || item === void 0 ? void 0 : (_item$meta4 = item.meta) === null || _item$meta4 === void 0 ? void 0 : (_item$meta4$attachmen = _item$meta4.attachments) === null || _item$meta4$attachmen === void 0 ? void 0 : _item$meta4$attachmen.comments) || 0) + " ")])])])];
      }
    }, {
      key: "item.data-table-expand",
      fn: function fn(_ref9) {
        var item = _ref9.item,
          expand = _ref9.expand,
          isExpanded = _ref9.isExpanded;
        return [item.value ? _c('v-icon', [_vm._v("mdi-minus")]) : _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return expand(!isExpanded);
            }
          }
        }, [_c('v-icon', {
          domProps: {
            "textContent": _vm._s(isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down')
          }
        })], 1)];
      }
    }, {
      key: "expanded-item",
      fn: function fn(_ref10) {
        var headers = _ref10.headers,
          item = _ref10.item;
        return [_c('td', {
          staticClass: "pa-0",
          attrs: {
            "colspan": headers.length
          }
        }, [_c('v-card', {
          attrs: {
            "flat": "",
            "tile": ""
          }
        }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('v-icon', {
          attrs: {
            "size": "20"
          },
          domProps: {
            "textContent": _vm._s('mdi-chevron-right')
          }
        }), _vm._v(" Origin: "), _c('b', [_vm._v(_vm._s(item.name))])], 1), _c('v-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('v-icon', {
          attrs: {
            "size": "20"
          },
          domProps: {
            "textContent": _vm._s('mdi-chevron-right')
          }
        }), _vm._v(" Region: "), _c('b', [_vm._v(_vm._s(item.region || "-"))])], 1), _c('v-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('v-icon', {
          attrs: {
            "size": "20"
          },
          domProps: {
            "textContent": _vm._s('mdi-chevron-right')
          }
        }), _vm._v(" Endpoint: "), _c('b', [_vm._v(_vm._s(item.endpoint || "-"))])], 1), _c('v-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('v-icon', {
          attrs: {
            "size": "20"
          },
          domProps: {
            "textContent": _vm._s('mdi-chevron-right')
          }
        }), _vm._v(" Key: "), _c('b', [_vm._v(_vm._s(item.key || "-"))])], 1), _c('v-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('v-icon', {
          attrs: {
            "size": "20"
          },
          domProps: {
            "textContent": _vm._s('mdi-chevron-right')
          }
        }), _vm._v(" Secret: "), _c('b', [_vm._v(_vm._s(item.secret || "-"))])], 1), _c('v-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('v-btn', {
          staticClass: "white--text font-weight-bold text-capitalize",
          attrs: {
            "color": "red",
            "depressed": "",
            "small": ""
          },
          on: {
            "click": function click($event) {
              [_vm.currentStorage = item, _vm.showRemoveModal = true];
            }
          }
        }, [_vm._v(" Remove ")])], 1)], 1)], 1)], 1)], 1)];
      }
    }], null, false, 957727024)
  }, [_c('template', {
    slot: "no-data"
  }, [_vm.getTeamPlanUsage && !_vm.getTeamPlanUsage.connect_storage ? _c('no-data', {
    attrs: {
      "first-text": "Connecting external storage available for Starter & Pro plans."
    }
  }) : _vm._e()], 1)], 2)], 1)], 1) : _c('v-col', {
    staticClass: "no-data-found"
  }, [_c('no-data', {
    attrs: {
      "first-text": "No storage to show",
      "second-text": "It seems that you don't have the permission to manage the team storage"
    }
  })], 1), _c('SidePanel', {
    model: {
      value: _vm.showStorageForm,
      callback: function callback($$v) {
        _vm.showStorageForm = $$v;
      },
      expression: "showStorageForm"
    }
  }, [_vm.showStorageForm ? _c('create-form', {
    on: {
      "refresh": function refresh($event) {
        _vm.showStorageForm = false;
      }
    }
  }) : _vm._e()], 1), _c('Modal', {
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        var _vm$currentStorage, _vm$currentStorage$me, _vm$currentStorage2, _vm$currentStorage2$m, _vm$currentStorage3, _vm$currentStorage3$m, _vm$currentStorage3$m2;
        return [_c('v-card', [_c('v-card-title', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Remove storage ")]), _c('v-divider'), _c('v-card-text', {
          staticClass: "my-4 message"
        }, [_c('p', [_vm._v("Removing a storage you agree for removing the following:")]), _c('div', [_c('ul', [_c('li', [_vm._v(" Apps ("), _c('span', {
          staticClass: "text-red"
        }, [_vm._v(_vm._s(((_vm$currentStorage = _vm.currentStorage) === null || _vm$currentStorage === void 0 ? void 0 : (_vm$currentStorage$me = _vm$currentStorage.meta) === null || _vm$currentStorage$me === void 0 ? void 0 : _vm$currentStorage$me.apps) || 0))]), _vm._v(") ")]), _c('ul', [_c('li', [_vm._v(" Releases ("), _c('span', {
          staticClass: "text-red"
        }, [_vm._v(_vm._s(((_vm$currentStorage2 = _vm.currentStorage) === null || _vm$currentStorage2 === void 0 ? void 0 : (_vm$currentStorage2$m = _vm$currentStorage2.meta) === null || _vm$currentStorage2$m === void 0 ? void 0 : _vm$currentStorage2$m.releases) || 0))]), _vm._v(") ")]), _c('li', [_vm._v(" Feedback ("), _c('span', {
          staticClass: "text-red"
        }, [_vm._v(_vm._s(((_vm$currentStorage3 = _vm.currentStorage) === null || _vm$currentStorage3 === void 0 ? void 0 : (_vm$currentStorage3$m = _vm$currentStorage3.meta) === null || _vm$currentStorage3$m === void 0 ? void 0 : (_vm$currentStorage3$m2 = _vm$currentStorage3$m.attachments) === null || _vm$currentStorage3$m2 === void 0 ? void 0 : _vm$currentStorage3$m2.feedback) || 0))]), _vm._v(") ")]), _c('li', [_vm._v("Timeline")]), _c('li', [_vm._v("Members")]), _c('li', [_vm._v("Chat")])]), _c('li', [_vm._v("Storage")]), _c('ul', [_c('li', [_vm._v("Name: " + _vm._s(_vm.currentStorage.name))]), _c('li', [_vm._v("Bucket: " + _vm._s(_vm.currentStorage.bucket))]), _c('li', [_vm._v("Region: " + _vm._s(_vm.currentStorage.region))])])])]), _c('br'), _c('p', [_vm._v("Are you sure want to proceed?")])]), _c('v-divider'), _c('v-card-actions', {
          staticClass: "py-3"
        }, [_c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.showRemoveModal = false;
            }
          }
        }, [_vm._v("No ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "loading": _vm.busy,
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary",
            "depressed": ""
          },
          on: {
            "click": _vm.deleteStorage
          }
        }, [_vm._v(" Yes ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showRemoveModal,
      callback: function callback($$v) {
        _vm.showRemoveModal = $$v;
      },
      expression: "showRemoveModal"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.mdAndDown,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 880 : '',
      "no-py": ""
    },
    on: {
      "close": function close($event) {
        _vm.showStartTrialModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_vm.showStartTrialModal ? _c('start-trial-modal', {
          attrs: {
            "tracker-event": _vm.trackerEvent,
            "from-connect-storage": "",
            "message": "Connecting external storage is available for Starter and Pro plans",
            "trigger-from": "externalStorage"
          },
          on: {
            "close": function close($event) {
              _vm.showStartTrialModal = false;
            },
            "open-subscribe": function openSubscribe($event) {
              _vm.openSubscriptionModal = true;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showStartTrialModal,
      callback: function callback($$v) {
        _vm.showStartTrialModal = $$v;
      },
      expression: "showStartTrialModal"
    }
  }), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.showUpgradeInfo = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('upgrade-plan', {
          attrs: {
            "from-external-storage": "",
            "info-text": "add external storage to your team",
            "title": "Connect External Storage"
          },
          on: {
            "close": function close($event) {
              _vm.showUpgradeInfo = false;
            }
          }
        }, [[_c('div', [_vm._v(" Do you need more storage size? Connect your external cloud storage."), _c('br'), _c('br'), _c('b', [_vm._v("The benefits:")]), _vm._v(" "), _c('br'), _c('br'), _vm._v(" - Unlimited releases"), _c('br'), _vm._v(" - No release expiry date"), _c('br'), _vm._v(" - No bandwidth restrictions"), _c('br'), _c('br'), _vm._v("This is free of charge from our end but charges will apply from the cloud storage provider. ")])]], 2)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showUpgradeInfo,
      callback: function callback($$v) {
        _vm.showUpgradeInfo = $$v;
      },
      expression: "showUpgradeInfo"
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }