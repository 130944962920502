var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "height": "200",
      "src": _vm.imagePath
    }
  })], 1), _c('v-col', {
    staticClass: "font-size-h3 black--text bold-text",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('v-col', {
    staticClass: "pt-0 bodyFont",
    attrs: {
      "cols": "12"
    }
  }, [_vm._t("default")], 2), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    staticClass: "text-right py-0",
    attrs: {
      "cols": "12"
    }
  }, [!_vm.showTrialButton ? _c('v-btn', {
    staticClass: "text-right text-transform-none",
    attrs: {
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.openSubscriptionModal = true;
      }
    }
  }, [_vm._v(" Upgrade now ")]) : _vm._e(), _vm.showTrialButton ? _c('v-btn', {
    staticClass: "text-right text-transform-none",
    attrs: {
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.openSubscriptionModal = true;
      }
    }
  }, [_vm._v(" Start free trial ")]) : _vm._e()], 1)], 1)], 1), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    on: {
      "close": function close($event) {
        _vm.openAddAddonPopup = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('add-addons', {
          attrs: {
            "from-upgrade": "",
            "addon-type": _vm.fromAppPage ? 'apps' : 'members'
          },
          on: {
            "close": function close($event) {
              _vm.openAddAddonPopup = false;
            },
            "success": function success($event) {
              _vm.openAddAddonPopup = false, _vm.$emit('success'), _vm.$emit('close');
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openAddAddonPopup,
      callback: function callback($$v) {
        _vm.openAddAddonPopup = $$v;
      },
      expression: "openAddAddonPopup"
    }
  }), _c('Modal', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 1100 : '',
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    on: {
      "close": function close($event) {
        _vm.openSubscriptionModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('plan-pricing', {
          attrs: {
            "from-modal": "",
            "from-upgrade-component": "",
            "upgrade-plan-text": _vm.getUpgradePlanText()
          },
          on: {
            "success": function success($event) {
              _vm.$emit('close'), _vm.openSubscriptionModal = false;
            },
            "close": function close($event) {
              _vm.openSubscriptionModal = false;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openSubscriptionModal,
      callback: function callback($$v) {
        _vm.openSubscriptionModal = $$v;
      },
      expression: "openSubscriptionModal"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }